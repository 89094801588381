import { useEffect, useState } from 'react';

import Skeleton from '@mui/material/Skeleton';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { callGet } from '@/api/fetcher';
import AttachmentIcon from '@/components/atoms/Icons/Attachment';
import {
  Attachment as AttachmentType,
  ConversationSource,
} from '@/models/chat';

import styles from './Attachment.module.scss';

interface Props {
  attachment: AttachmentType;
  source: ConversationSource;
}

export const createMediaUrl = (file: Buffer, mimeType: string) => {
  if (file) {
    // Convert ArrayBuffer data to Uint8Array before passing to Blob
    const uint8Array = new Uint8Array(file);
    const blob = new Blob([uint8Array], { type: mimeType });
    const url = URL.createObjectURL(blob);
    const cleanup = () => URL.revokeObjectURL(url);

    return { url, cleanup };
  }

  return { url: null, cleanup: () => {} };
};

export const Attachment = ({ attachment, source }: Props) => {
  const [mediaUrl, setMediaUrl] = useState<string>(null);
  const { t } = useTranslation();
  const routerUrl = `/www/attachment-url/integration/${source?.channel}/${source.integrationId}/${attachment.id}`;

  const {
    data: file,
    isLoading,
    error,
  } = useQuery<Buffer>({
    queryKey: [routerUrl],
    queryFn: async () => {
      const res = await callGet(routerUrl, { responseType: 'arrayBuffer' });

      return await res.arrayBuffer();
    },
    enabled: source?.channel === 'whatsapp',
    staleTime: 60 * 1000,
  });

  useEffect(() => {
    if (file) {
      const { url, cleanup } = createMediaUrl(file, attachment.mime_type);
      if (url) {
        setMediaUrl(url);
      }

      return cleanup;
    }
  }, [attachment?.mime_type, file]);

  const isImage = attachment.mime_type.includes('image');
  const isAudio = attachment.mime_type.includes('audio');
  const isVideo = attachment.mime_type.includes('video');
  const isFile = attachment.type === 'file';
  if (isLoading) {
    return <Skeleton variant="rectangular" width="300px" height="200px" />;
  }

  if (error) {
    return (
      <img
        className={styles.brokenImage}
        src="/assets/broken_image.png"
        alt={t('common.fallback_image')}
      />
    );
  }

  return (
    <div className={styles.container}>
      {isImage && <img src={mediaUrl} alt="Attachment" />}
      {isAudio && <audio src={mediaUrl} controls />}
      {isVideo && <video src={mediaUrl} controls />}
      {isFile && (
        <a href={mediaUrl} target="_blank" rel="noopener noreferrer">
          <AttachmentIcon color="var(--icon-default-blue)" />
          {attachment.filename}
        </a>
      )}
    </div>
  );
};
