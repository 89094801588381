import { useEffect, useMemo, useState } from 'react';

import Typography from '@mui/material/Typography';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import PlainFieldset from '@/components/atoms/Fieldset/templates/PlainFieldset';
import { ActivityLogs } from '@/components/pages/ActivityLogs/ActivityLogs';
import { useActivityLogs } from '@/hooks/useActivityLogs';
import useDesks from '@/hooks/useDesks';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectAccountSlug, selectDeskId } from '@/redux/session/selectors';
import { urlSafeBase64Encode } from '@/util/util';

import HomeAnalytics from './fieldsets/HomeAnalytics/HomeAnalytics';
import useHomeOverview from './fieldsets/HomeOverview/useHomeOverview';

import styles from './HomeCompleted.module.scss';

const HomeCompleted = () => {
  const { t } = useTranslation();
  const slug = useSelector(selectAccountSlug);
  const deskId = useSelector(selectDeskId);
  const { desk } = useDesks(deskId);
  const { rulesWithBrains, isLoading } = useHomeOverview();

  const brainIds = useMemo(
    () => rulesWithBrains?.map(({ brain }) => brain?.brain_parent_id) ?? [],
    [rulesWithBrains]
  );

  const [isAnalyticsEmpty, setAnalyticsEmpty] = useState(false);
  useEffect(() => {
    setAnalyticsEmpty(false);
  }, [slug, deskId]);
  const canReadAnalytics = useSelector((state: RootState) =>
    getPermissions(state, 'analytics', actions.READ)
  );

  const canReadLogs = useSelector((state: RootState) =>
    getPermissions(state, 'audit_logs', actions.READ)
  );

  const getBrainProp = () => {
    if (brainIds.length > 1) {
      return { brain_ids: brainIds };
    }
    return { brainId: brainIds[0] };
  };

  const { isDataEmpty: isBrainEmpty } = useActivityLogs(
    slug,
    undefined,
    null,
    getBrainProp()?.brain_ids,
    getBrainProp()?.brainId
  );
  const { isDataEmpty: isEnvironmentEmpty } = useActivityLogs(
    slug,
    undefined,
    deskId
  );

  if (!deskId || !brainIds) {
    return null;
  }
  const deskFilter = desk
    ? [
        {
          type: 'deskIds',
          deskIds: [
            {
              value: desk.desk_id,
              label: desk.name,
            },
          ],
        },
      ]
    : [];
  const brainFilter = rulesWithBrains
    ? [
        {
          type: 'brainIds',
          brainIds: rulesWithBrains?.map(({ brain }) => ({
            value: brain?.brain_parent_id,
            label: brain?.name,
          })),
        },
      ]
    : [];

  const analyticsDate = {
    startDate: moment().subtract(1, 'months').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
  };

  const logsDate = {
    startDate: moment().subtract(1, 'week').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
  };
  const deskUrl = `/${slug}/account/activity-logs?date=${urlSafeBase64Encode(
    JSON.stringify(logsDate)
  )}&filters=${urlSafeBase64Encode(JSON.stringify(deskFilter))}`;
  const brainUrl = `/${slug}/account/activity-logs?date=${urlSafeBase64Encode(
    JSON.stringify(logsDate)
  )}&filters=${urlSafeBase64Encode(JSON.stringify(brainFilter))}`;

  const analyticsUrl = `/${slug}/analytics/overview?date=${urlSafeBase64Encode(
    JSON.stringify(analyticsDate)
  )}&filters=${urlSafeBase64Encode(JSON.stringify(deskFilter))}`;

  return (
    <div className={styles.container}>
      {canReadAnalytics && !isAnalyticsEmpty && (
        <div className={styles.analyticsWrapper}>
          <div className={styles.header}>
            <Typography variant="label-caps-big">
              {t('home.monthly_insights')}
            </Typography>
            <Link to={analyticsUrl} className={styles.link}>
              <Typography>{t('home.more_metrics')}</Typography>
            </Link>
          </div>
          <HomeAnalytics setAnalyticsEmpty={setAnalyticsEmpty} />
        </div>
      )}
      {canReadLogs && (
        <>
          {!isBrainEmpty && !isLoading && (
            <div className={styles.logWrapper}>
              <div className={styles.header}>
                <Typography variant="label-caps-big">
                  {t('home.recent_brain')}
                </Typography>
                <Link to={brainUrl} className={styles.link}>
                  <Typography> {t('home.view_all')}</Typography>
                </Link>
              </div>
              <PlainFieldset fullWidth>
                <div className={styles.content}>
                  <ActivityLogs hideFilters {...getBrainProp()} deskId={null} />
                </div>
              </PlainFieldset>
            </div>
          )}
          {desk && !isLoading && !isEnvironmentEmpty && (
            <div className={styles.logWrapper}>
              <div className={styles.header}>
                <Typography variant="label-caps-big">
                  {t('home.recent_environment')}
                </Typography>
                <Link to={deskUrl} className={styles.link}>
                  <Typography> {t('home.view_all')}</Typography>
                </Link>
              </div>

              <PlainFieldset fullWidth>
                <div className={styles.content}>
                  <ActivityLogs
                    hideFilters
                    deskId={deskId}
                    brain_ids={[]}
                    brainId={null}
                  />
                </div>
              </PlainFieldset>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default HomeCompleted;
