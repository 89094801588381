import { useCallback, useState } from 'react';

import AttachFile from '@mui/icons-material/AttachFile';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { Avatar } from '@/components/atoms/Avatar/Avatar/Avatar';
import IconButton from '@/components/atoms/IconButton/IconButton';
import { AudioIcon } from '@/components/atoms/Icons/AudioIcon';
import Brain from '@/components/atoms/Icons/Brain';
import Edit from '@/components/atoms/Icons/Edit';
import CarouselOld from '@/components/organisms/Dialogs/CarouselOld/CarouselOld';
import { useAccount } from '@/hooks/useAccount';
import useDialogs from '@/hooks/useDialogs';
import { useMarkdownToHtml } from '@/hooks/useMarkdownToHtml';
import { ConversationSource } from '@/models/chat';
import { Message, NodeStack, TryItCollection } from '@/models/tryIt';
import { IconBroadcast } from '@/modules/broadcast/icons/Broadcast';
import { Attachment } from '@/modules/humanChat/components/Conversation/MessageArea/Attachment/Attachment';
import { useMessageBubble } from '@/modules/TryIt/hooks/useMessageBubble';
import { DatabaseIcon } from '@/modules/TryIt/Icons/DatabaseIcon';
import { GoToResponseIcon } from '@/modules/TryIt/Icons/GoToResponseIcon';
import {
  ThumbsDownIcon,
  ThumbsUpIcon,
} from '@/modules/TryIt/Icons/ThumbsIcons';
import {
  selectTryItAction,
  selectTryItRequisite,
} from '@/modules/TryIt/redux/actions';
import { selectIsTryItReplay } from '@/modules/TryIt/redux/selectors';
import { removeDuplicateDocuments } from '@/modules/TryIt/utils/helper';
import { getActionsSelector } from '@/redux/dialogs/selectors';
import { popModal } from '@/redux/modals/actions';
import { noop } from '@/util/util';

import { highlightEntities } from './helper';
import { ResponsesWrapper } from './ResponsesWrapper/ResponsesWrapper';

import styles from '../MessageNode/MessageNode.module.scss';

export interface MessageBubbleProps {
  message: Message;
  onOptionClick: (text: string) => void;
  onPostback: (text: string) => void;
  collection?: TryItCollection;
  nodes_stack?: NodeStack[];
  brainId: string;
  author_type?: string;
  author_id?: string;
  isAccountReplay?: boolean;
  isAudio?: boolean;
  source: ConversationSource;
}

const PromptMessage = ({
  text,
  entities,
  onGoToEntity,
  t,
  isAudio,
  attachments,
  source,
}) => {
  return (
    <div className={styles.prompt}>
      {attachments && (
        <Box mb={1}>
          <Attachment attachment={attachments?.[0]} source={source} />
        </Box>
      )}
      {isAudio && (
        <span className={styles.audioTitle}>
          <AudioIcon color="var(--text-default-white)" />
          <Typography
            variant="label-regular"
            color="var(--text-default-white)"
            className={styles.audio}
          >
            {t('conversation.audio_message')}
          </Typography>
        </span>
      )}

      <Typography variant="body-regular" component="p">
        {highlightEntities(text, entities, onGoToEntity)}
      </Typography>
    </div>
  );
};

const generateFragments = (number: number) => {
  let fragments = '';
  for (let i = 1; i <= number; i++) {
    fragments += `<span class="${styles.documentNumber}">${i}</span>`;
  }
  return `<span>${fragments}</span>`;
};

// Subcomponent
const ResponseMessages = ({
  response,
  onOptionClick,
  onPostback,
  collection,
  onResponseClick,
  isAccountReplay,
}) => {
  const isCollectionReply =
    !!collection?.fragments &&
    collection.fragments.length > 0 &&
    collection?.response_code === 0;
  const isBroadcastReply = !isCollectionReply && !response.action_id;
  const { html } = useMarkdownToHtml(response.text);
  const { t } = useTranslation();
  const uniqueDocuments = removeDuplicateDocuments(collection?.fragments || []);
  const fragments = generateFragments(uniqueDocuments?.length || 0);
  const matchAnyClosingTagRegex = /(<\/\w+>)$/;
  const htmlWithFragments = html.replace(
    matchAnyClosingTagRegex,
    fragments + '$1'
  );

  const [optionChosen, setOptionChosen] = useState(false);
  const isReplay = useSelector(selectIsTryItReplay);

  const handleOptionClick = (text: string) => {
    if (!isReplay) {
      onOptionClick(text);
      setOptionChosen(true);
    }
  };

  const Icon = () => {
    if (isCollectionReply) {
      return <DatabaseIcon color="var(--icon-default-gray)" size={24} />;
    } else if (isBroadcastReply) {
      return (
        <IconBroadcast
          color="var(--icon-default-gray)"
          className={styles.responseTypeIcon}
          size={24}
        />
      );
    }
    return (
      <Brain
        color="var(--icon-default-gray)"
        className={styles.responseTypeIcon}
        size={24}
      />
    );
  };

  switch (response.type) {
    case 'text':
    case 'disambiguation':
      // for test webhook
      if (response.texts) {
        return (
          <>
            {response.texts.map((text, index) => (
              <p
                // eslint-disable-next-line react/no-array-index-key
                key={`${text}_${index}`}
                className={cn(styles.bubble, styles.brain)}
              >
                {text}
              </p>
            ))}
            {!optionChosen && response.options && (
              <div className={styles.optionsContainer}>
                {response.options.map((option) => (
                  <button
                    key={`option-${option.text}`}
                    className={cn(styles.bubble, styles.option, {
                      [styles.optionDisabled]: isReplay,
                    })}
                    onClick={() => {}}
                    type="button"
                  >
                    {option.label}
                  </button>
                ))}
              </div>
            )}
          </>
        );
      }

      return (
        <>
          <div className={styles.response}>
            <Icon />

            <div className={cn(styles.bubble, styles.brain)}>
              <Typography
                variant="body-regular"
                component="span"
                dangerouslySetInnerHTML={{
                  __html:
                    isCollectionReply && !isAccountReplay
                      ? htmlWithFragments
                      : html,
                }}
              />

              {/* Hide icons until implementation is ready */}
              {false && isCollectionReply && !isReplay && (
                <div className={styles.collectionActions}>
                  <IconButton ariaLabel={t('common.edit')} onClick={noop}>
                    <Edit />
                  </IconButton>
                  <IconButton ariaLabel={t('try_it.thumbs_up')} onClick={noop}>
                    <ThumbsUpIcon />
                  </IconButton>
                  <IconButton
                    ariaLabel={t('try_it.thumbs_down')}
                    onClick={noop}
                  >
                    <ThumbsDownIcon />
                  </IconButton>
                </div>
              )}
            </div>

            {!isReplay &&
              !isCollectionReply &&
              response.type !== 'disambiguation' && (
                <IconButton
                  ariaLabel={t('try_it.go_to_response')}
                  onClick={() => onResponseClick(response.action_id)}
                >
                  <GoToResponseIcon className={styles.goToResponseIcon} />
                </IconButton>
              )}
          </div>

          {!optionChosen && response.options && (
            <div className={styles.optionsContainer}>
              {response.options.map((option) => (
                <Typography
                  variant="body-regular"
                  key={JSON.stringify(option)}
                  className={cn(styles.option, {
                    [styles.optionDisabled]: isReplay,
                  })}
                  onClick={() => handleOptionClick(option.text)}
                  component="button"
                >
                  {option.label}
                </Typography>
              ))}
            </div>
          )}
        </>
      );
    case 'url':
      return (
        <div className={styles.response}>
          <Brain
            color="var(--icon-default-gray)"
            className={styles.responseTypeIcon}
            size={24}
          />
          <p className={cn(styles.bubble, styles.brain)}>
            <a
              href={response.url}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                display: 'flex',
                alignItems: 'center',
                color: 'var(--text-default-blue)',
              }}
            >
              {response.url}
            </a>
          </p>

          {!isReplay && !isCollectionReply && (
            <IconButton
              ariaLabel={t('try_it.go_to_response')}
              onClick={() => onResponseClick(response.action_id)}
            >
              <GoToResponseIcon className={styles.goToResponseIcon} />
            </IconButton>
          )}
        </div>
      );
    case 'audio':
      return (
        <div className={styles.response}>
          <Brain
            color="var(--icon-default-gray)"
            className={styles.responseTypeIcon}
            size={24}
          />
          <p className={cn(styles.bubble, styles.brain, styles.isMedia)}>
            <audio src={response.url} controls />
          </p>
        </div>
      );
    case 'image':
      return (
        <div className={styles.response}>
          <Brain
            color="var(--icon-default-gray)"
            className={styles.responseTypeIcon}
            size={24}
          />
          <p className={cn(styles.bubble, styles.brain, styles.isMedia)}>
            <img src={response.url} alt={response.name} />
          </p>
          {!isReplay && !isCollectionReply && (
            <IconButton
              ariaLabel={t('try_it.go_to_response')}
              onClick={() => onResponseClick(response.action_id)}
            >
              <GoToResponseIcon className={styles.goToResponseIcon} />
            </IconButton>
          )}
        </div>
      );
    case 'video':
      return (
        <div className={styles.response}>
          <Brain
            color="var(--icon-default-gray)"
            className={styles.responseTypeIcon}
            size={24}
          />
          <p className={cn(styles.bubble, styles.brain, styles.isMedia)}>
            <video src={response.url} controls />
          </p>
          {!isReplay && !isCollectionReply && (
            <IconButton
              ariaLabel={t('try_it.go_to_response')}
              onClick={() => onResponseClick(response.action_id)}
            >
              <GoToResponseIcon className={styles.goToResponseIcon} />
            </IconButton>
          )}
        </div>
      );
    case 'file':
      return (
        <div className={styles.response}>
          <Brain
            color="var(--icon-default-gray)"
            className={styles.responseTypeIcon}
            size={24}
          />
          <p className={cn(styles.bubble, styles.brain)}>
            <a
              href={response.url}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                display: 'flex',
                alignItems: 'center',
                color: 'var(--text-default-blue)',
              }}
            >
              <AttachFile style={{ marginRight: 'var(--space-4)' }} />
              {response.name}
            </a>
          </p>
          {!isReplay && !isCollectionReply && (
            <IconButton
              ariaLabel={t('try_it.go_to_response')}
              onClick={() => onResponseClick(response.action_id)}
            >
              <GoToResponseIcon className={styles.goToResponseIcon} />
            </IconButton>
          )}
        </div>
      );
    case 'carousel':
      return (
        <div className={styles.response}>
          <Brain
            color="var(--icon-default-gray)"
            className={styles.responseTypeIcon}
            size={24}
          />
          <div className={styles.carouselContainer}>
            <CarouselOld
              onPostback={onPostback}
              cards={response.cards}
              isReplay={isReplay}
              slidesPerView={1.75}
            />
          </div>
          {!isReplay && !isCollectionReply && (
            <IconButton
              ariaLabel={t('try_it.go_to_response')}
              onClick={() => onResponseClick(response.action_id)}
            >
              <GoToResponseIcon className={styles.goToResponseIcon} />
            </IconButton>
          )}
        </div>
      );
    case 'webview':
    case 'survey':
      return (
        <div className={styles.response}>
          <Brain
            color="var(--icon-default-gray)"
            className={styles.responseTypeIcon}
            size={24}
          />
          <div className={styles.webviewContainer}>
            <p>{response.name}</p>
            <div className={styles.webviewButtonContainer}>
              <p>{response.label}</p>
            </div>
          </div>
          {!isReplay && !isCollectionReply && (
            <IconButton
              ariaLabel={t('try_it.go_to_response')}
              onClick={() => onResponseClick(response.action_id)}
            >
              <GoToResponseIcon className={styles.goToResponseIcon} />
            </IconButton>
          )}
        </div>
      );
    default:
      return null;
  }
};

export const MessageBubble = ({
  message,
  onOptionClick,
  onPostback,
  collection,
  nodes_stack,
  brainId,
  author_type,
  author_id,
  isAccountReplay,
  source,
}: MessageBubbleProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { slug } = useAccount();
  const { onGoToEntity } = useMessageBubble();
  const { dialogs } = useDialogs(brainId);
  const allActions = getActionsSelector(dialogs);
  const dispatch = useDispatch();

  const goToResponse = useCallback(
    (actionId: string) => {
      const selectedAction = allActions.find(
        (a: { action_id: string }) => a.action_id === actionId
      );
      if (!brainId || !selectedAction || !actionId) {
        return;
      }

      dispatch(popModal());
      // select an action inside a requisite or an action of a node
      if (selectedAction?.if_no_requisite) {
        dispatch(
          selectTryItRequisite({
            node_id: selectedAction.node_id,
            requisite_idx: selectedAction.requisite_idx,
            if_no_requisite: selectedAction.if_no_requisite,
          })
        );
      } else if (selectedAction.requisite_idx == null) {
        dispatch(
          selectTryItAction({
            node_id: selectedAction.node_id,
            action_id: actionId,
          })
        );
      } else {
        dispatch(
          selectTryItRequisite({
            node_id: selectedAction.node_id,
            requisite_idx: selectedAction.requisite_idx,
          })
        );
      }
      navigate(
        `/${slug}/brains/${brainId}/dialogs/${selectedAction.dialog_id}`
      );
    },
    [allActions, brainId, dispatch, navigate, slug]
  );

  switch (message.type) {
    case 'broadcast': {
      return (
        <>
          {message.responses?.map((response) => (
            <ResponseMessages
              key={`${JSON.stringify(response)}`}
              response={response}
              onOptionClick={onOptionClick}
              onPostback={onPostback}
              collection={collection}
              onResponseClick={goToResponse}
              isAccountReplay={isAccountReplay}
            />
          ))}
        </>
      );
    }
    case 'user':
      return (
        <PromptMessage
          text={message.text}
          entities={message.entities}
          onGoToEntity={onGoToEntity}
          t={t}
          isAudio={message.isAudio}
          attachments={message?.attachments}
          source={source}
        />
      );
    case 'agent':
      return (
        <Box display="flex" alignItems="center">
          {author_type === 'agent' && (
            <Avatar size="medium" userId={author_id} />
          )}
          <p className={cn(styles.bubble, styles.agent)}>{message.text}</p>
        </Box>
      );
    case 'brain':
      return (
        <ResponsesWrapper nodes_stack={nodes_stack}>
          {message.responses.map((response) => (
            <ResponseMessages
              key={`${JSON.stringify(response)}`}
              response={response}
              onOptionClick={onOptionClick}
              onPostback={onPostback}
              collection={collection}
              onResponseClick={goToResponse}
              isAccountReplay={isAccountReplay}
            />
          ))}
        </ResponsesWrapper>
      );
    case 'error': {
      return <p className={cn(styles.bubble, styles.error)}>{message.text}</p>;
    }

    default:
      return null;
  }
};
