import { useCallback, useEffect, useRef, useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { sendMessage } from '@/api/client';
import useHomeCheckList, {
  AccountUserPrefsEnum,
} from '@/hooks/useHomeCheckList';
import { Brain } from '@/models/brain';
import { RootState } from '@/models/state';
import {
  BrainMessage,
  Message,
  MessageSent,
  NodeType,
  UserMessage,
} from '@/models/tryIt';
import { LogMessage } from '@/modules/analytics/models';
import {
  resetSelectedMessage,
  updateContext,
  updateSessionId,
  updateSelectedMessage,
} from '@/modules/TryIt/redux/actions';
import {
  selectContext,
  selectIsTryItReplay,
} from '@/modules/TryIt/redux/selectors';
import { randId } from '@/redux/dialogs/helper';

const isSameMinute = (a, b) => {
  return a.format('HH:mm') === b.format('HH:mm');
};

const useTryIt = (
  messages: LogMessage[],
  brain: Brain,
  tags?: string[],
  webHost?: string
) => {
  const { accountUserChecklist, markAsComplete } = useHomeCheckList();

  const { messageNodes, sessionId } = useSelector((state: RootState) => {
    return {
      messageNodes: state.tryIt.selectedMessage.nodes,
      sessionId: state.tryIt.sessionId,
    };
  }, shallowEqual);
  const isReplay = useSelector(selectIsTryItReplay);
  const storeContext = useSelector(selectContext);

  const bodyRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const widgetRef = useRef<HTMLDivElement>(null);

  const dispatch = useDispatch();

  const [nodes, setNodes] = useState<NodeType[]>(messageNodes);
  const [activeNode, setActiveNode] = useState<number>();

  const [isInputDisabled, setIsInputDisabled] = useState(false);

  const handleReset = useCallback(() => {
    setNodes([]);
    dispatch(updateSessionId(uuidv4()));
  }, [dispatch]);

  useEffect(() => {
    // Format messages when isReplay
    if (messages && isReplay) {
      const newNodes: NodeType[] = messages.reduce((acc, message, idx) => {
        const time = message.time;

        switch (message.event) {
          case 'message:received': {
            const resp = messages.find(
              (m, index) =>
                m.request_id === message.request_id &&
                m.event === 'message:brain_send' &&
                index > idx
            ) as Extract<LogMessage, { event: 'message:brain_send' }> | null;
            // user -> live agent
            if (!resp) {
              let exists = false;
              acc.forEach((node) => {
                if (
                  node.messages.find((m) => m.request_id === message.request_id)
                ) {
                  exists = true;
                }
              });
              if (exists) {
                return acc;
              }
              const index = messages.findIndex(
                (m) => m.request_id === message.request_id
              );
              const userMessages = [
                {
                  intents: null,
                  request_id: message.request_id,
                  text: message.message.text,
                  type: 'user',
                  isAudio: message.message.type === 'audio',
                  attachments: message.message?.attachments,
                },
              ];
              for (let i = index + 1; i < messages.length; i++) {
                const nextMessage = messages[i];
                if (
                  nextMessage.event === 'message:received' &&
                  isSameMinute(moment(time), moment(messages[i].time))
                ) {
                  userMessages.push({
                    intents: null,
                    request_id: messages[i].request_id,
                    text: nextMessage.message.text,
                    type: 'user',
                    isAudio: nextMessage.message.type === 'audio',
                    attachments: nextMessage.message?.attachments,
                  });
                } else {
                  break;
                }
              }

              return [
                ...acc,
                {
                  context: message.context,
                  messages: userMessages,
                  author_type: 'user',
                  time,
                },
              ];
            }
            // user <-> brain
            const nodes_stack = resp?.message?.debug?.nodes_stack ?? [];
            const isDisambiguation =
              resp?.message?.responses?.[0]?.type === 'disambiguation';
            const standalone_question =
              resp?.message?.debug?.standalone_question;

            return [
              ...acc,
              {
                id: nodes_stack[nodes_stack.length - 1]?.node_id,
                context: resp.context,
                name: isDisambiguation
                  ? null
                  : nodes_stack[nodes_stack.length - 1]?.name,
                debugLogs: resp.message.debug.logs,
                steps: resp.message.debug.steps,
                time,
                nodes_stack,
                standalone_question,
                collection: {
                  collection_id: resp?.collection_id,
                  request_code: resp?.collection_request_code,
                  response_code: resp?.collection_response_code,
                  fragments: resp?.collection_sources,
                },
                messages: [
                  {
                    entities: resp.message.entities,
                    intents: resp.message.intents,
                    text: message.message.text,
                    type: 'user',
                    isAudio: message.message.type === 'audio',
                    attachments: message.message?.attachments,
                  },
                  {
                    responses: resp.message.responses,
                    type: 'brain',
                  },
                ],
                author_type: 'brain',
                author_id: resp?.brain_parent_id,
              },
            ];
          }
          case 'message:send': {
            let exists = false;
            acc.forEach((node) => {
              if (
                node.messages.find((m) => m.request_id === message.request_id)
              ) {
                exists = true;
              }
            });
            if (exists) {
              return acc;
            }
            const index = messages.findIndex(
              (m) => m.request_id === message.request_id
            );
            const agentMessages = [
              {
                request_id: message.request_id,
                text: message.message.text,
                type: 'agent',
              },
            ];
            for (let i = index + 1; i < messages.length; i++) {
              const nextMessage = messages[i];
              if (
                nextMessage.event === 'message:send' &&
                isSameMinute(moment(time), moment(nextMessage.time)) &&
                nextMessage.agent_id === message.agent_id
              ) {
                agentMessages.push({
                  request_id: messages[i].request_id,
                  text: nextMessage.message.text,
                  type: 'agent',
                });
              } else {
                break;
              }
            }
            // live agent -> user
            return [
              ...acc,
              {
                time,
                context: message.context,
                messages: agentMessages,
                author_type: 'agent',
                author_id: message.agent_id,
              },
            ];
          }
          case 'message:broadcast_send': {
            const messages = [
              {
                responses: message.message.responses,
                type: 'broadcast',
              },
            ];

            return [
              ...acc,
              {
                context: message.context,
                tags: message.tags,
                time,
                messages,
                broadcast_id: message.message.debug.broadcast.broadcast_id,
                author_type: 'broadcast',
              },
            ];
          }
          default:
            return acc;
        }
      }, []);
      setNodes(newNodes);
      return () => {
        dispatch(resetSelectedMessage());
        dispatch(updateContext([{}]));
      };
    }
    return () => {};
  }, [dispatch, isReplay, messages]);

  useEffect(() => {
    if (!sessionId && !isReplay) {
      dispatch(updateSessionId(uuidv4()));
    }
  }, [dispatch, isReplay, sessionId]);

  useEffect(() => {
    if (!isEmpty(nodes)) {
      const debugLogs = nodes[nodes.length - 1]?.debugLogs;
      let context;
      if (!messages) {
        context = nodes[nodes.length - 1]?.context;
      } else {
        context = tags
          ? { ...nodes[nodes.length - 1]?.context, tags }
          : nodes[nodes.length - 1]?.context;
      }
      setActiveNode(nodes.length - 1);
      if (bodyRef.current) {
        bodyRef.current.scrollTop = bodyRef.current.scrollHeight;
      }

      dispatch(
        updateSelectedMessage({
          selectedMessage: {
            debugLogs,
            context,
            nodes,
            index: nodes.length - 1,
          },
        })
      );
    }
  }, [dispatch, isReplay, messages, nodes, tags]);

  const addMessage = useCallback(
    (message: Message, completeNode = false) => {
      let nodeKey;
      const lastNode = nodes[nodes.length - 1];
      const createNewNode = lastNode ? lastNode.isComplete : true;
      if (createNewNode) {
        nodeKey = randId();
        setNodes([
          ...nodes,
          {
            intents: undefined,
            key: nodeKey,
            messages: [message],
            isComplete: false,
            name: null,
            id: null,
            context: undefined,
            debugLogs: undefined,
            collection: undefined,
            nodes_stack: undefined,
          },
        ]);
        return nodeKey;
      }

      nodeKey = lastNode?.key;
      const copy = [...nodes];
      copy[copy.length - 1] = {
        ...lastNode,
        messages: [...lastNode.messages, message],
        isComplete: completeNode,
      };
      setNodes(copy);
      return nodeKey;
    },
    [nodes]
  );

  // Handles user input
  const handleInput = useCallback(
    async (inputText: string) => {
      // If the 'brain' object is not initialized, exit the function early.
      if (!brain) {
        return;
      }

      // Checks if a specific user preference is not set, and if so, marks it as complete.
      if (!accountUserChecklist[AccountUserPrefsEnum.TEST_DIALOG]) {
        markAsComplete(AccountUserPrefsEnum.TEST_DIALOG);
      }

      // Disables user input to prevent multiple inputs during processing.
      setIsInputDisabled(true);

      // Generates a unique identifier for the new message.
      const messageSentId = randId();

      // Creates a new message object with the user's input.
      const newMessage: UserMessage = {
        text: inputText,
        id: messageSentId,
        type: 'user',
        intents: undefined,
        entities: undefined,
      };

      // Adds the new message to the messages list and retrieves its node key.
      const nodeKey = addMessage(newMessage);

      // Prepares a message object to be sent to the backend.
      const messageSent: MessageSent = {
        session_id: sessionId,
        input: {
          text: newMessage.text,
        },
        channel: 'test',
        context: storeContext[storeContext.length - 1] ?? {},
      };

      try {
        // Sends the message to the backend and waits for a response.
        const data = await sendMessage(
          brain.brain_id,
          messageSent,
          undefined,
          webHost
        );

        // Extracts data from the backend's response.
        const { nodes_stack } = data.output.debug;

        // Updates the context with the new data from the response.
        dispatch(updateContext([...storeContext, data.context]));

        // Determines the current node from the nodes stack.
        let realNode = nodes_stack
          ? nodes_stack[nodes_stack.length - 1]
          : undefined;

        // Prepares the response message from the backend.
        const responseMessage: BrainMessage | null = data.output.responses
          .length
          ? {
              id: randId(),
              type: 'brain',
              responses: data.output.responses,
            }
          : null;

        // Special handling for disambiguation in the response.
        if (responseMessage?.responses?.[0]?.type === 'disambiguation') {
          realNode = {
            ...realNode,
            name: null,
          };
        }

        // Updates the nodes with new messages and information.
        setNodes((prev) =>
          prev.map((node) => {
            if (node.key !== nodeKey) {
              return node;
            }
            return {
              ...node,
              intents: data.output.intents,
              name: realNode?.name ?? null,
              id: realNode?.node_id ?? null,
              context: data.context,
              debugLogs: data.output.debug.logs,
              isComplete: true,
              collection: data.output.debug.collection,
              standalone_question: data.output.debug.standalone_question,
              nodes_stack: data.output.debug.nodes_stack,
              steps: data.output.debug.steps,
              messages: [
                // Updates the previous message with intents and entities from the response.
                ...node.messages.map((msg: BrainMessage) =>
                  msg.id === messageSentId
                    ? {
                        ...msg,
                        intents: data.output.intents,
                        entities: data.output.entities,
                      }
                    : msg
                ),
                // Adds the response message to the node.
                ...(responseMessage ? [responseMessage] : []),
              ],
            };
          })
        );
      } catch (error) {
        console.error(error);
        dispatch(updateContext([...storeContext, {}]));
        setNodes((prev) =>
          prev.map((node) => {
            if (node.key !== nodeKey) {
              return node;
            }
            return {
              ...node,
              isComplete: true,
              messages: [
                // Updates the message to show there was an error.
                ...node.messages.map((msg: BrainMessage) =>
                  msg.id === messageSentId ? { ...msg, intents: null } : msg
                ),
                // Adds an error message.
                { id: randId(), type: 'error', text: error.message },
              ],
            };
          })
        );
      } finally {
        // Re-enables user input after processing is complete.
        setIsInputDisabled(false);
      }
    },
    [
      brain,
      accountUserChecklist,
      addMessage,
      sessionId,
      storeContext,
      markAsComplete,
      webHost,
      dispatch,
    ]
  );

  const handleNodeClick = useCallback(
    (index: number) => {
      if (index !== activeNode) {
        setActiveNode(index);
        dispatch(
          updateSelectedMessage({
            selectedMessage: {
              debugLogs: nodes[index].debugLogs,
              context: tags
                ? { ...nodes[index]?.context, tags }
                : nodes[index]?.context,
              nodes,
              index,
            },
          })
        );
      }
    },
    [activeNode, dispatch, nodes, tags]
  );

  return {
    nodes,
    isReplay,
    activeNode,
    isInputDisabled,
    handleReset,
    handleInput,
    handleNodeClick,
    bodyRef,
    inputRef,
    widgetRef,
  };
};

export default useTryIt;
