import { useCallback } from 'react';

import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import Button from '@/components/atoms/Button/Button/Button';
import StatusBadge from '@/components/atoms/StatusBadge/StatusBadge';

import { Check } from './Check';
import { BillingState, ModalPlanData, PLAN_IDS } from '../../model';

import styles from './PlanCard.module.scss';

interface Props {
  plan: ModalPlanData;
  onPlanSelect: (planId: string) => void;
  isLoading: boolean;
  billingState: BillingState;
}

export const PlanCard = ({
  plan,
  onPlanSelect,
  isLoading,
  billingState,
}: Props) => {
  const { t } = useTranslation();

  const isPlanCancelled = billingState?.is_canceled && billingState.has_expired;
  const isActive = billingState?.plan_id === plan.plan_id;
  const shouldShowButton = !isActive || isPlanCancelled;

  const handleClick = useCallback(() => {
    onPlanSelect(plan.plan_id);
  }, [onPlanSelect, plan.plan_id]);

  const showDownGradeButton =
    billingState?.plan_id === PLAN_IDS.GROWTH && plan.plan_id === PLAN_IDS.PRO;

  return (
    <div
      className={cn(styles.card, {
        [styles.isActive]: isActive,
      })}
    >
      <div className={styles.title}>
        <Typography variant="heading1-medium" color="var(--text-default-black)">
          {plan.title}{' '}
          {isActive && (
            <StatusBadge label={t('billing.current_plan')} variant="neutral" />
          )}
          {plan.plan_id === PLAN_IDS.GROWTH && !isActive && (
            <StatusBadge label={t('onboarding.most_popular')} variant="info" />
          )}
        </Typography>
        <Typography
          variant="heading2-regular"
          color="var(--text-default-black)"
        >
          {plan.subtitle}
        </Typography>
      </div>
      <div className={styles.main}>
        {plan?.body.map((item) => (
          <span key={item.text} className={styles.item}>
            {item.number ? (
              <Typography
                variant="body-medium"
                color="var(--text-default-blue)"
                className={styles.number}
              >
                {item.number}
              </Typography>
            ) : (
              <Check />
            )}
            <span className={styles.line}>
              <Typography
                variant="body-medium"
                color="var(--text-default-black)"
                className={styles.text}
              >
                {item.text}
              </Typography>

              {item.secondaryText && (
                <Typography
                  color="var(--text-default-black)"
                  variant="label-nav-regular"
                >
                  {item.secondaryText}
                </Typography>
              )}
            </span>
          </span>
        ))}
        <Typography color="var(--text-default-black)" className={styles.more}>
          {t('billing.more')}
        </Typography>
      </div>
      {shouldShowButton && (
        <div className={styles.button}>
          <Button
            onClick={handleClick}
            isLoading={isLoading}
            variant={showDownGradeButton ? 'secondary' : 'primary'}
          >
            {showDownGradeButton
              ? t('billing.downgrade')
              : t('billing.upgrade')}
          </Button>
        </div>
      )}
      {!shouldShowButton && billingState.has_expired && (
        <div className={styles.button}>
          <Button
            onClick={handleClick}
            isLoading={isLoading}
            variant={showDownGradeButton ? 'secondary' : 'primary'}
          >
            {t('billing.renew')}
          </Button>
        </div>
      )}
    </div>
  );
};
