import { useMemo } from 'react';

import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { broadcastsEndpoints as endpoints } from '@/api/endpoints';
import { callDelete, callGet, callPost, callPut } from '@/api/fetcher';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import {
  Broadcast,
  Broadcasts,
  PartialBroadcast,
  WhatsappTemplates,
} from '@/modules/broadcast/models';
import {
  addErrorTemporalToast,
  addTemporalToast,
} from '@/modules/notifications/redux/actions';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectAccountId } from '@/redux/session/selectors';

import { selectBroadcast, selectBroadcastDeskId } from '../redux/selectors';

export const API = Object.freeze({
  listBroadcasts: async (): Promise<Broadcasts> =>
    callGet(endpoints.broadcasts),

  getBroadcast: async (broadcastId: string): Promise<Broadcast> =>
    callGet(endpoints.broadcast(broadcastId)),

  createBroadcast: async (
    newBroadcast: Partial<Broadcast>
  ): Promise<Broadcast> => callPost(endpoints.broadcasts, newBroadcast),

  updateBroadcast: async ({
    broadcast_id,
    ...broadcast
  }: PartialBroadcast): Promise<Broadcast> =>
    callPut(endpoints.broadcast(broadcast_id), broadcast),

  deleteBroadcast: async (
    broadcastId: string,
    force?: boolean
  ): Promise<Broadcast> => {
    const queryParams = force ? `?force=${force}` : '';
    return callDelete(`${endpoints.broadcast(broadcastId)}${queryParams}`);
  },
  whatsAppTemplates: (
    desk_id: string,
    integration_id: string
  ): Promise<WhatsappTemplates> =>
    callGet(endpoints.whatsAppTemplates(desk_id, integration_id)),
});

interface DeleteProps {
  broadcast_id: string;
  force?: boolean;
}

export const onBroadcastUpdated = (
  queryClient: QueryClient,
  broadcast: Broadcast
) => {
  queryClient.setQueryData<Broadcast>(
    [endpoints.broadcast(broadcast.broadcast_id)],
    (prev: Broadcast) => ({ ...prev, ...broadcast })
  );
  const queryKey = [endpoints.broadcasts, broadcast.account_id];
  if (queryClient.getQueryData(queryKey)) {
    queryClient.setQueryData<Broadcasts>(queryKey, (prev: Broadcasts) => ({
      broadcasts: (prev?.broadcasts || []).map((item) =>
        item.broadcast_id === broadcast.broadcast_id
          ? { ...item, ...broadcast }
          : item
      ),
    }));
  }
};

export const onBroadcastCreated = (
  queryClient: QueryClient,
  broadcast: Broadcast
) => {
  const queryKey = [endpoints.broadcasts, broadcast.account_id];
  if (queryClient.getQueryData(queryKey)) {
    queryClient.setQueryData<Broadcasts>(queryKey, (prev) => ({
      broadcasts: [
        broadcast,
        ...(prev?.broadcasts || []).filter(
          (acc) => acc.broadcast_id !== broadcast.broadcast_id
        ),
      ],
    }));
  }
};

export const onBroadcastRemoved = (
  queryClient: QueryClient,
  account_id: string,
  broadcast_id: string
) => {
  const queryKey = [endpoints.broadcasts, account_id];
  if (queryClient.getQueryData(queryKey)) {
    queryClient.setQueryData<Broadcasts>(queryKey, (prev: Broadcasts) => ({
      broadcasts: (prev?.broadcasts || []).filter(
        (acc) => acc.broadcast_id !== broadcast_id
      ),
    }));
  }
  queryClient.removeQueries({ queryKey: [endpoints.broadcast(broadcast_id)] });
};

export const useBroadcasts = (broadcastId?: string) => {
  const queryClient = useQueryClient();
  const accountId = useSelector(selectAccountId);
  const deskId = useSelector(selectBroadcastDeskId);
  const draftBroadcast = useSelector(selectBroadcast);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const canRead = useSelector((state: RootState) =>
    getPermissions(state, 'broadcasts', actions.READ)
  );

  const { data: broadcasts, status: listStatus } = useQuery<Broadcasts, Error>({
    queryKey: [endpoints.broadcasts, accountId],
    queryFn: () => API.listBroadcasts(),
    enabled: !!accountId && canRead,
  });

  const {
    data: broadcast,
    status: getStatus,
    isLoading: isBroadcastLoading,
  } = useQuery<Broadcast, Error>({
    queryKey: [endpoints.broadcast(broadcastId)],
    queryFn: () => API.getBroadcast(broadcastId),
    enabled: !!broadcastId && canRead && broadcastId !== 'draft',
    refetchInterval: 5 * 1000,
  });

  const { data: whatsAppTemplates, status: listwhatsAppTemplatesStatus } =
    useQuery<WhatsappTemplates, Error>({
      queryKey: [
        endpoints.whatsAppTemplates(deskId, draftBroadcast.integration_id),
      ],
      queryFn: () =>
        API.whatsAppTemplates(deskId, draftBroadcast.integration_id),
      enabled: Boolean(deskId) && !!draftBroadcast.integration_id,
    });

  const {
    mutate: createBroadcast,
    mutateAsync: createBroadcastAsync,
    status: createStatus,
  } = useMutation<Broadcast, Error, Partial<Broadcast>>({
    mutationFn: (broadcast) => API.createBroadcast(broadcast),
    onSuccess: (resp) => {
      onBroadcastCreated(queryClient, resp);
      dispatch(
        addTemporalToast(
          'success',
          t('broadcasts.broadcast_created', { 0: resp.name })
        )
      );
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const {
    mutate: updateBroadcast,
    mutateAsync: updateBroadcastAsync,
    status: updateStatus,
  } = useMutation<Broadcast, Error, PartialBroadcast>({
    mutationFn: API.updateBroadcast,
    onSuccess: (resp) => {
      onBroadcastUpdated(queryClient, resp);
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });
  const { mutate: deleteBroadcast, status: deleteStatus } = useMutation<
    Broadcast,
    Error,
    DeleteProps
  >({
    mutationFn: ({ broadcast_id, force }) =>
      API.deleteBroadcast(broadcast_id, force),
    onSuccess: (resp) => {
      onBroadcastRemoved(queryClient, accountId, resp.broadcast_id);
      dispatch(addTemporalToast('success', t('broadcasts.broadcast_deleted')));
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const broadcastNames = useMemo(
    () => broadcasts?.broadcasts?.map((b) => b.name.toLowerCase()),
    [broadcasts?.broadcasts]
  );

  return {
    broadcasts: broadcasts?.broadcasts,
    broadcast,
    whatsAppTemplates: whatsAppTemplates?.templates,
    listwhatsAppTemplatesStatus,
    createStatus,
    getStatus,
    listStatus,
    updateStatus,
    createBroadcast,
    createBroadcastAsync,
    updateBroadcast,
    updateBroadcastAsync,
    deleteBroadcast,
    deleteStatus,
    broadcastNames,
    isBroadcastLoading,
  };
};
