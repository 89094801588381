import { useCallback, useMemo, useState } from 'react';

import Typography from '@mui/material/Typography';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Button from '@/components/atoms/Button/Button/Button';
import Modal from '@/components/organisms/Modals/Modal';
import { useBilling } from '@/modules/billing/hooks/useBilling';
import {
  BillingState,
  ModalPlanData,
  PLAN_IDS,
  Plan,
} from '@/modules/billing/model';
import { popModal } from '@/redux/modals/actions';
import { CONTACT_SALES_URL, PRICING_URL } from '@/util/constants';

import { PlanCard } from './PlanCard';

import styles from './PlanCard.module.scss';

function createPlanData(plan: Plan, t): ModalPlanData {
  const baseData = { title: plan.name, plan_id: plan.plan_id };
  switch (plan.plan_id) {
    case PLAN_IDS.ENTERPRISE:
      return null;
    case PLAN_IDS.PRO:
      return {
        ...baseData,
        subtitle: t('billing.pro_plan.subtitle'),
        body: [
          { text: t('billing.pro_plan.one') },
          { text: t('billing.pro_plan.two') },
          { text: t('billing.pro_plan.three') },
          { text: t('billing.pro_plan.four') },
          { text: t('billing.pro_plan.five'), number: '3' },
          { text: t('billing.pro_plan.six'), number: '3' },
          {
            text: t('billing.pro_plan.seven'),
            secondaryText: t('billing.pro_plan.seven_secondary'),
            number: '100',
          },
        ],
      };
    case PLAN_IDS.GROWTH:
      return {
        ...baseData,
        subtitle: t('billing.growth_plan.subtitle'),
        body: [
          { text: t('billing.growth_plan.one') },
          { text: t('billing.growth_plan.two') },
          { text: t('billing.growth_plan.three') },
          { text: t('billing.growth_plan.four') },
          { text: t('billing.growth_plan.five'), number: '10' },
          {
            text: t('billing.growth_plan.six'),
            number: '10',
            secondaryText: t('billing.growth_plan.six_secondary'),
          },
          {
            text: t('billing.growth_plan.seven'),
            secondaryText: t('billing.growth_plan.seven_secondary'),
            number: '1000',
          },
        ],
      };
    default:
      return baseData;
  }
}

const ModalUpgradePlan = ({
  onSuccess,
}: {
  onSuccess: (plan: BillingState['plan_type']) => Promise<void>;
}) => {
  const { t } = useTranslation();
  const { plans, billingState } = useBilling();
  const [isLoading, setIsLoading] = useState(false);

  const planData = useMemo(
    () =>
      plans
        .map((plan) => createPlanData(plan, t))
        .filter((plan) => plan !== null),
    [plans, t]
  );

  const dispatch = useDispatch();

  const handleContactSale = useCallback(() => {
    window.open(CONTACT_SALES_URL, '_blank');
    dispatch(popModal());
  }, [dispatch]);

  const handlePlanSelect = useCallback(
    async (plan: BillingState['plan_type']) => {
      setIsLoading(true);

      await onSuccess(plan);
      setIsLoading(false);
    },
    [onSuccess]
  );

  const handleCloseModal = useCallback(() => {
    dispatch(popModal());
  }, [dispatch]);

  const isPlanExpired =
    billingState.plan_id !== 'trial' && billingState.has_expired;

  return (
    <Modal
      title={
        isPlanExpired ? t('billing.choose_plan') : t('billing.upgrade_plan')
      }
      onSecondarySubmit={handleCloseModal}
      size="medium"
      passiveModal
    >
      <div className={styles.wrapper}>
        <div className={styles.cards}>
          {planData?.map((plan) => (
            <PlanCard
              key={plan.plan_id}
              plan={plan}
              billingState={billingState}
              onPlanSelect={handlePlanSelect}
              isLoading={isLoading}
            />
          ))}
        </div>
        <Button
          variant="tertiary"
          size="small"
          isLink
          href={PRICING_URL}
          target="_blank"
        >
          <Typography>{t('billing.compare_benefits')}</Typography>
        </Button>
        <div className={styles.footer}>
          <span className={styles.textFooter}>
            <Typography
              variant="heading2-regular"
              color="var(--text-default-black)"
            >
              <Trans
                i18nKey="billing.upgrade_plan_modal_footer"
                values={{ team: t('billing.bigger_team'), plan: 'Enterprise' }}
                components={{ strong: <strong /> }}
              />
            </Typography>
            <Typography>{t('billing.footer')}</Typography>
          </span>
          <span className={styles.button}>
            <Button variant="secondary" onClick={handleContactSale}>
              {t('billing.contact_sales')}
            </Button>
          </span>
        </div>
      </div>
    </Modal>
  );
};

export default ModalUpgradePlan;
